<template>
  <div>
    <b-table
      id="table-transition"
      show-empty
      :fields="header"
      hover
      responsive="xl"
      :items="orderdetail.paymentdetail"
      sort-icon-left
    >
      <template #cell(No)="row"> {{ row.index + 1 }}. </template>
      <template #cell(created)="row">
        {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
      </template>
      <template #cell(detail)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2 btn-success">
          {{ row.detailsShowing ? 'Hide' : 'Detail' }}
        </b-button>
      </template>
      <template #row-details="row">
        <div class="row">
          <div class="col-sm-6">
            <span class="font-weight-bold">Data Request</span>
            <pre>{{ JSON.stringify(row.item.request_data, null, 2) }}</pre>
          </div>
          <div class="col-sm-6">
            <span class="font-weight-bold">Data Response</span>
            <pre>{{ JSON.stringify(row.item.response_data, null, 2) }}</pre>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: 'CimbDetail',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      header: [
        { key: 'No', label: 'No' },
        { key: 'created', label: 'Created' },
        { key: 'detail', label: 'Detail' },
      ],
    };
  },
  methods: {
    formatDate(tgl) {
      return new Date(tgl).toString();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
};
</script>
