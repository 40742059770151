var render = function () {
  var _vm$orderdetail, _vm$orderdetail2, _vm$orderdetail3, _vm$orderdetail4, _vm$orderdetail5, _vm$orderdetail6, _vm$orderdetail7, _vm$orderdetail8, _vm$orderdetail9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Created")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail = _vm.orderdetail) !== null && _vm$orderdetail !== void 0 && _vm$orderdetail.created ? _vm.formatDate((_vm$orderdetail2 = _vm.orderdetail) === null || _vm$orderdetail2 === void 0 ? void 0 : _vm$orderdetail2.created) : '-') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Currency")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail3 = _vm.orderdetail) !== null && _vm$orderdetail3 !== void 0 && _vm$orderdetail3.currency_code ? (_vm$orderdetail4 = _vm.orderdetail) === null || _vm$orderdetail4 === void 0 ? void 0 : _vm$orderdetail4.currency_code : '-') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Amount")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail5 = _vm.orderdetail) !== null && _vm$orderdetail5 !== void 0 && _vm$orderdetail5.amount ? _vm.formatPrice((_vm$orderdetail6 = _vm.orderdetail) === null || _vm$orderdetail6 === void 0 ? void 0 : _vm$orderdetail6.amount, (_vm$orderdetail7 = _vm.orderdetail) === null || _vm$orderdetail7 === void 0 ? void 0 : _vm$orderdetail7.currency_code) : '-') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Status Active")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$orderdetail8 = _vm.orderdetail) !== null && _vm$orderdetail8 !== void 0 && _vm$orderdetail8.is_active ? _c('span', {
    staticClass: "badge badge-pill badge-primary"
  }, [_vm._v(" active ")]) : _c('span', {
    staticClass: "badge badge-pill badge-danger"
  }, [_vm._v(" inactive ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Trial Test")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$orderdetail9 = _vm.orderdetail) !== null && _vm$orderdetail9 !== void 0 && _vm$orderdetail9.is_trial ? _c('span', {
    staticClass: "badge badge-pill badge-warning"
  }, [_vm._v(" true ")]) : _c('span', {
    staticClass: "badge badge-pill badge-primary"
  }, [_vm._v(" false ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }