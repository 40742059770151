<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2">Created</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{ orderdetail?.created ? formatDate(orderdetail?.created) : '-' }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Currency</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{ orderdetail?.currency_code ? orderdetail?.currency_code : '-' }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Amount</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{
          orderdetail?.amount
            ? formatPrice(orderdetail?.amount, orderdetail?.currency_code)
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Status Active</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        <span
          v-if="orderdetail?.is_active"
          class="badge badge-pill badge-primary"
        >
          active
        </span>
        <span v-else class="badge badge-pill badge-danger">
          inactive
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Trial Test</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        <span
          v-if="orderdetail?.is_trial"
          class="badge badge-pill badge-warning"
        >
          true
        </span>
        <span v-else class="badge badge-pill badge-primary">
          false
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DetailScoopWallet',
  props: {
    orderdetail: {
      required: true,
    },
  },
  methods: {
    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    formatPrice(value, currencyCode) {
      if (currencyCode?.toLowerCase() == 'idr') {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
  },
};
</script>
