<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2">Payment ID</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{ orderdetail?.id ? orderdetail?.id : '-' }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Payment Status</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        <div v-if="orderdetail?.payment_status">
          <paymentStatusVue
            :payment="parseInt(orderdetail?.payment_status, 10)"
          />
        </div>
        <div v-else>
          -
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Payment Type</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        <div v-if="Object.keys(orderdetail)?.length">
          <span
            style="cursor: pointer"
            class="badge badge-warning"
            data-toggle="tooltip"
            data-placement="top"
            title="Update Payment Type To Production"
            v-if="orderdetail?.is_test_payment"
            @click="
              editPaymentType(orderdetail?.is_test_payment, orderdetail?.id)
            "
            >TESTING</span
          >
          <span
            style="cursor: pointer"
            class="badge badge-primary"
            data-toggle="tooltip"
            data-placement="top"
            title="Update Payment Type To Testing"
            v-if="!orderdetail?.is_test_payment"
            @click="
              editPaymentType(orderdetail?.is_test_payment, orderdetail?.id)
            "
            >PRODUCTION</span
          >
        </div>
        <div v-else>
          -
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Payment Date</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{
          orderdetail?.payment_datetime
            ? formatDate(orderdetail?.payment_datetime)
            : '-'
        }}
      </div>
    </div>
  </div>
</template>
<script>
import paymentStatusVue from './paymentStatus.vue';
export default {
  name: 'DetailPayment',
  components: {
    paymentStatusVue,
  },
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
    updatePaymentType: {
      type: Function,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toString();
    },

    editPaymentType(type, id) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You will update payment type to ${
          type ? 'PRODUCTION' : 'TESTING'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          this.updatePaymentType(type, id);
        }
      });
    },
  },
};
</script>
