<template>
  <div>
    <b-table
      id="table-transition"
      show-empty
      :fields="header"
      hover
      responsive="xl"
      :items="orderdetail.paymentinappbillings"
      sort-icon-left
    >
      <template #cell(No)="row"> {{ row.index + 1 }}. </template>
      <template #cell(created)="row">
        <div style="width: 200px">
          {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
        </div>
      </template>
      <template #cell(status)="row">
        <span class="badge badge-pill badge-info">
          {{ row.item?.status }}
        </span>
      </template>
      <template #cell(purchase_token)="row">
        <div style="width: 350px">
          {{
            row.item?.purchase_token
              ? row.item?.purchase_token
              : 'PurchaseToken Notfound'
          }}
          <b-button
            v-if="row.item?.purchase_token"
            size="sm"
            variant="outline"
            @click="copyTextToClipboard(row.item?.purchase_token)"
          >
            <i class="fa fa-copy"></i>
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            v-b-modal.modal-insert-purchase-token-g-payment
            v-if="!showButtonInsertToken"
          >
            Insert Token
          </b-button>
        </div>
      </template>
      <template #cell(iab)="row">
        <div style="width: 350px">
          {{
            row.item?.iab_order_id
              ? row.item?.iab_order_id
              : 'GPA Order Not Found'
          }}
          <b-button
            size="sm"
            variant="primary"
            v-b-modal.modal-insert-gpa
            v-if="!showButtonInsertGPA"
          >
            Insert GPA
          </b-button>
        </div>
      </template>
    </b-table>

    <!-- ======= modal insert purchase token for google payment -->
    <b-modal
      id="modal-insert-purchase-token-g-payment"
      :title="'Insert Token for order : ' + orderdetail.order_id"
      :ok-visible="true"
    >
      <b-form id="form-insert-token-g-payment" @submit.prevent="onSubmitToken">
        <b-form-group>
          <b-form-input
            v-model="purchase_token"
            id="token-g-payment"
            placeholder="put token here.."
            required
          >
          </b-form-input>
          <br />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            form="form-insert-token-g-payment"
            type="submit"
            >Save</b-button
          >
        </div>
      </template>
    </b-modal>

    <!-- ======= modal insert GPA for google payment -->
    <b-modal
      id="modal-insert-gpa"
      :title="'Insert GPA for order : ' + orderdetail?.order_id"
      :ok-visible="true"
    >
      <b-form id="form-insert-gpa" @submit.prevent="onSubmitGPA">
        <b-form-group>
          <b-form-input
            v-model="google_iab"
            placeholder="GPA.3328-1533-0108-86973"
            required
          >
          </b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" form="form-insert-gpa" type="submit"
            >Save</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailGoogle',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
    submitToken: {
      type: Function,
      required: true,
    },
    submitGPA: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      purchase_token: '',
      google_iab: null,
      header: [
        { key: 'No', label: 'No' },
        { key: 'created', label: 'Payment Time' },
        { key: 'status', label: 'Status' },
        { key: 'purchase_token', label: 'Purchase Token' },
        { key: 'iab', label: 'IAB Order ID' },
      ],
      showButtonInsertToken: false,
      showButtonInsertGPA: false,
    };
  },
  watch: {
    orderdetail: function() {
      this.showButtonInsertToken = this.orderdetail.paymentinappbillings.some(
        (e) => e.purchase_token,
      );
      this.showButtonInsertGPA = this.orderdetail.paymentinappbillings.some(
        (e) => e.iab_order_id,
      );
    },
  },
  created: function() {
    this.showButtonInsertToken = this.orderdetail.paymentinappbillings.some(
      (e) => e.purchase_token,
    );
    this.showButtonInsertGPA = this.orderdetail.paymentinappbillings.some(
      (e) => e.iab_order_id,
    );
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    onSubmitToken() {
      this.submitToken({
        purchase_token: this.purchase_token,
        order_id: this.orderdetail?.order_id,
      });
      this.$bvModal.hide('modal-insert-purchase-token-g-payment');
      this.purchase_token = '';
    },
    onSubmitGPA() {
      this.submitGPA({
        google_iab: this.google_iab,
        order_id: this.orderdetail?.order_id,
      });
      this.$bvModal.hide('modal-insert-gpa');
      this.google_iab = null;
    },
  },
};
</script>
