var render = function () {
  var _vm$orderdetail, _vm$orderdetail2, _vm$orderdetail3, _vm$orderdetail4, _Object$keys, _vm$orderdetail5, _vm$orderdetail8, _vm$orderdetail11, _vm$orderdetail12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Payment ID")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail = _vm.orderdetail) !== null && _vm$orderdetail !== void 0 && _vm$orderdetail.id ? (_vm$orderdetail2 = _vm.orderdetail) === null || _vm$orderdetail2 === void 0 ? void 0 : _vm$orderdetail2.id : '-') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Payment Status")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$orderdetail3 = _vm.orderdetail) !== null && _vm$orderdetail3 !== void 0 && _vm$orderdetail3.payment_status ? _c('div', [_c('paymentStatusVue', {
    attrs: {
      "payment": parseInt((_vm$orderdetail4 = _vm.orderdetail) === null || _vm$orderdetail4 === void 0 ? void 0 : _vm$orderdetail4.payment_status, 10)
    }
  })], 1) : _c('div', [_vm._v(" - ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Payment Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_Object$keys = Object.keys(_vm.orderdetail)) !== null && _Object$keys !== void 0 && _Object$keys.length ? _c('div', [(_vm$orderdetail5 = _vm.orderdetail) !== null && _vm$orderdetail5 !== void 0 && _vm$orderdetail5.is_test_payment ? _c('span', {
    staticClass: "badge badge-warning",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "data-toggle": "tooltip",
      "data-placement": "top",
      "title": "Update Payment Type To Production"
    },
    on: {
      "click": function ($event) {
        var _vm$orderdetail6, _vm$orderdetail7;
        return _vm.editPaymentType((_vm$orderdetail6 = _vm.orderdetail) === null || _vm$orderdetail6 === void 0 ? void 0 : _vm$orderdetail6.is_test_payment, (_vm$orderdetail7 = _vm.orderdetail) === null || _vm$orderdetail7 === void 0 ? void 0 : _vm$orderdetail7.id);
      }
    }
  }, [_vm._v("TESTING")]) : _vm._e(), !((_vm$orderdetail8 = _vm.orderdetail) !== null && _vm$orderdetail8 !== void 0 && _vm$orderdetail8.is_test_payment) ? _c('span', {
    staticClass: "badge badge-primary",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "data-toggle": "tooltip",
      "data-placement": "top",
      "title": "Update Payment Type To Testing"
    },
    on: {
      "click": function ($event) {
        var _vm$orderdetail9, _vm$orderdetail10;
        return _vm.editPaymentType((_vm$orderdetail9 = _vm.orderdetail) === null || _vm$orderdetail9 === void 0 ? void 0 : _vm$orderdetail9.is_test_payment, (_vm$orderdetail10 = _vm.orderdetail) === null || _vm$orderdetail10 === void 0 ? void 0 : _vm$orderdetail10.id);
      }
    }
  }, [_vm._v("PRODUCTION")]) : _vm._e()]) : _c('div', [_vm._v(" - ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Payment Date")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$orderdetail11 = _vm.orderdetail) !== null && _vm$orderdetail11 !== void 0 && _vm$orderdetail11.payment_datetime ? _vm.formatDate((_vm$orderdetail12 = _vm.orderdetail) === null || _vm$orderdetail12 === void 0 ? void 0 : _vm$orderdetail12.payment_datetime) : '-') + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }