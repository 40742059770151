var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.header,
      "hover": "",
      "responsive": "xl",
      "items": _vm.orderdetail.paymentdetail,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        var _row$item, _row$item2;
        return [_vm._v(" " + _vm._s((_row$item = row.item) !== null && _row$item !== void 0 && _row$item.created ? _vm.formatDate((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.created) : '-') + " ")];
      }
    }, {
      key: "cell(detail)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "mr-2 btn-success",
          attrs: {
            "size": "sm"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" " + _vm._s(row.detailsShowing ? 'Hide' : 'Detail') + " ")])];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Data Request")]), _c('pre', [_vm._v(_vm._s(JSON.stringify(row.item.request_data, null, 2)))])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Data Response")]), _c('pre', [_vm._v(_vm._s(JSON.stringify(row.item.response_data, null, 2)))])])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }