<template>
  <div class="text-black">
    <span v-if="payment === 20001" class="badge badge-pill">
      <img src="../../assets/img/money.png" class="icon" alt="" srcset="" />
      WAITING FOR PAYMENT
    </span>
    <span v-if="payment === 20002" class="badge badge-pill">
      <img src="../../assets/img/money.png" class="icon" alt="" srcset="" />
      PAYMENT PROSES
    </span>
    <span v-if="payment === 20003" class="badge badge-pill">
      <img src="../../assets/img/dollar.png" class="icon" alt="" srcset="" />
      PAYMENT BILLED
    </span>
    <span v-if="payment === 20004" class="badge badge-pill">
      <i class="fa fa-repeat" aria-hidden="true"></i>
      PAYMENT RESTORED
    </span>
    <span v-if="payment === 50000" class="badge badge-pill">
      <i class="fa fa-times text-danger" aria-hidden="true"></i>
      PAYMENT CANCELLED
    </span>
    <span v-if="payment === 50001" class="badge badge-pill">
      <i class="fa fa-times text-danger" aria-hidden="true"></i>
      PAYMENT ERROR
    </span>
    <span v-if="payment === 50002" class="badge badge-pill">
      <i class="fa fa-times text-danger" aria-hidden="true"></i>
      DELIVERY ERROR
    </span>
    <span v-if="payment === 50003" class="badge badge-pill">
      <i class="fa fa-stop text-danger" aria-hidden="true"></i>
      PAYMENT DENIED
    </span>
    <span v-if="payment === 50004" class="badge badge-pill">
      <i class="fa fa-stop text-danger" aria-hidden="true"></i>
      PAYMENT EXPIRED
    </span>
    <span v-if="payment === 10001" class="badge badge-pill">
      <img src="../../assets/img/coins.png" class="icon" alt="" srcset="" />
      NEW
    </span>
  </div>
</template>

<script>
export default {
  name: 'paymentStatus',
  props: {
    payment: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped>
.icon {
  width: 17px;
}
.badge {
  color: black;
}
</style>
