<template>
  <div>
    <div v-if="orderdetail?.paymentva?.length">
      <b-table
        id="table-transition"
        show-empty
        :fields="headerVA"
        hover
        responsive="xl"
        :items="orderdetail.paymentva"
        sort-icon-left
      >
        <template #cell(No)="row"> {{ row.index + 1 }}. </template>
        <template #cell(created)="row">
          {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
        </template>
        <template #cell(bank_account)="row">
          {{
            row.item?.bank_account ? row.item?.bank_account.toUpperCase() : '-'
          }}
        </template>
        <template #cell(virtual_number)="row">
          {{ row.item?.virtual_number ? row.item?.virtual_number : '-' }}
        </template>
        <template #cell(detail)="row">
          <b-button
            size="sm"
            @click="row.toggleDetails"
            class="mr-2 btn-success"
          >
            {{ row.detailsShowing ? 'Hide' : 'Detail' }}
          </b-button>
        </template>
        <template #row-details="row">
          <div class="row">
            <div class="col-sm-6">
              <span class="font-weight-bold">Data Request</span>
              <pre>{{ JSON.stringify(row.item.data_request, null, 2) }}</pre>
            </div>
            <div class="col-sm-6">
              <span class="font-weight-bold">Data Response</span>
              <pre>{{ JSON.stringify(row.item.data_response, null, 2) }}</pre>
            </div>
          </div>
        </template>
      </b-table>
    </div>

    <div v-else>
      <b-table
        id="table-transition"
        show-empty
        :fields="header"
        hover
        responsive="xl"
        :items="orderdetail.paymentdetail"
        sort-icon-left
      >
        <template #cell(No)="row"> {{ row.index + 1 }}. </template>
        <template #cell(created)="row">
          {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
        </template>
        <template #cell(detail)="row">
          <b-button
            size="sm"
            @click="row.toggleDetails"
            class="mr-2 btn-success"
          >
            {{ row.detailsShowing ? 'Hide' : 'Detail' }}
          </b-button>
        </template>
        <template #row-details="row">
          <div class="row">
            <div class="col-sm-6">
              <span class="font-weight-bold">Data Request</span>
              <pre>{{ JSON.stringify(row.item.request_data, null, 2) }}</pre>
            </div>
            <div class="col-sm-6">
              <span class="font-weight-bold">Data Response</span>
              <pre>{{ JSON.stringify(row.item.response_data, null, 2) }}</pre>
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailVA',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      header: [
        { key: 'No', label: 'No' },
        { key: 'created', label: 'Created' },
        { key: 'detail', label: 'Detail' },
      ],
      headerVA: [
        { key: 'No', label: 'No' },
        { key: 'created', label: 'Created' },
        { key: 'bank_account', label: 'Bank' },
        { key: 'virtual_number', label: 'VA Number' },
        { key: 'status', label: 'Status' },
        { key: 'detail', label: 'Detail' },
      ],
    };
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
  },
};
</script>
