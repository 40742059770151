var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-black"
  }, [_vm.payment === 20001 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/money.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" WAITING FOR PAYMENT ")]) : _vm._e(), _vm.payment === 20002 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/money.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" PAYMENT PROSES ")]) : _vm._e(), _vm.payment === 20003 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/dollar.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" PAYMENT BILLED ")]) : _vm._e(), _vm.payment === 20004 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-repeat",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" PAYMENT RESTORED ")]) : _vm._e(), _vm.payment === 50000 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-times text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" PAYMENT CANCELLED ")]) : _vm._e(), _vm.payment === 50001 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-times text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" PAYMENT ERROR ")]) : _vm._e(), _vm.payment === 50002 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-times text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" DELIVERY ERROR ")]) : _vm._e(), _vm.payment === 50003 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-stop text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" PAYMENT DENIED ")]) : _vm._e(), _vm.payment === 50004 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-stop text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" PAYMENT EXPIRED ")]) : _vm._e(), _vm.payment === 10001 ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/coins.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" NEW ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }