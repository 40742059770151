var render = function () {
  var _vm$orderdetail;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.header,
      "hover": "",
      "responsive": "xl",
      "items": _vm.orderdetail.paymentinappbillings,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        var _row$item, _row$item2;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item = row.item) !== null && _row$item !== void 0 && _row$item.created ? _vm.formatDate((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.created) : '-') + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        var _row$item3;
        return [_c('span', {
          staticClass: "badge badge-pill badge-info"
        }, [_vm._v(" " + _vm._s((_row$item3 = row.item) === null || _row$item3 === void 0 ? void 0 : _row$item3.status) + " ")])];
      }
    }, {
      key: "cell(purchase_token)",
      fn: function (row) {
        var _row$item4, _row$item5, _row$item6;
        return [_c('div', {
          staticStyle: {
            "width": "350px"
          }
        }, [_vm._v(" " + _vm._s((_row$item4 = row.item) !== null && _row$item4 !== void 0 && _row$item4.purchase_token ? (_row$item5 = row.item) === null || _row$item5 === void 0 ? void 0 : _row$item5.purchase_token : 'PurchaseToken Notfound') + " "), (_row$item6 = row.item) !== null && _row$item6 !== void 0 && _row$item6.purchase_token ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline"
          },
          on: {
            "click": function ($event) {
              var _row$item7;
              return _vm.copyTextToClipboard((_row$item7 = row.item) === null || _row$item7 === void 0 ? void 0 : _row$item7.purchase_token);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-copy"
        })]) : _vm._e(), !_vm.showButtonInsertToken ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-insert-purchase-token-g-payment",
            modifiers: {
              "modal-insert-purchase-token-g-payment": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "primary"
          }
        }, [_vm._v(" Insert Token ")]) : _vm._e()], 1)];
      }
    }, {
      key: "cell(iab)",
      fn: function (row) {
        var _row$item8, _row$item9;
        return [_c('div', {
          staticStyle: {
            "width": "350px"
          }
        }, [_vm._v(" " + _vm._s((_row$item8 = row.item) !== null && _row$item8 !== void 0 && _row$item8.iab_order_id ? (_row$item9 = row.item) === null || _row$item9 === void 0 ? void 0 : _row$item9.iab_order_id : 'GPA Order Not Found') + " "), !_vm.showButtonInsertGPA ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-insert-gpa",
            modifiers: {
              "modal-insert-gpa": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "primary"
          }
        }, [_vm._v(" Insert GPA ")]) : _vm._e()], 1)];
      }
    }])
  }), _c('b-modal', {
    attrs: {
      "id": "modal-insert-purchase-token-g-payment",
      "title": 'Insert Token for order : ' + _vm.orderdetail.order_id,
      "ok-visible": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "form": "form-insert-token-g-payment",
            "type": "submit"
          }
        }, [_vm._v("Save")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    attrs: {
      "id": "form-insert-token-g-payment"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmitToken.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "id": "token-g-payment",
      "placeholder": "put token here..",
      "required": ""
    },
    model: {
      value: _vm.purchase_token,
      callback: function ($$v) {
        _vm.purchase_token = $$v;
      },
      expression: "purchase_token"
    }
  }), _c('br')], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-insert-gpa",
      "title": 'Insert GPA for order : ' + ((_vm$orderdetail = _vm.orderdetail) === null || _vm$orderdetail === void 0 ? void 0 : _vm$orderdetail.order_id),
      "ok-visible": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "form": "form-insert-gpa",
            "type": "submit"
          }
        }, [_vm._v("Save")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form', {
    attrs: {
      "id": "form-insert-gpa"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmitGPA.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "GPA.3328-1533-0108-86973",
      "required": ""
    },
    model: {
      value: _vm.google_iab,
      callback: function ($$v) {
        _vm.google_iab = $$v;
      },
      expression: "google_iab"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }