<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h1 class="text-primary">Detail Order</h1>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Detail Transaction
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <label class="col-sm-2">Order ID</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.id }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Email</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <router-link
                          :to="{
                            path: '/customers/detail/' + item?.users?.email,
                          }"
                          target="_blank"
                        >
                          {{ item.users ? item.users.email : '-' }}
                        </router-link>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Transaction Time</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ formatDate(item.created) }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Modified</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ formatDate(item.modified) }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Order Number</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.order_number }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Remote Order Number</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{
                          item.remote_order_number
                            ? item.remote_order_number
                            : '-'
                        }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Status Order</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div class="float-left" style="margin-top: 0px">
                          <paymentview :payment="item.order_status" />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Renewal Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <span
                          v-if="item.is_renewal"
                          class="badge badge-pill badge-primary"
                        >
                          Renewal
                        </span>
                        <span v-else class="badge badge-pill badge-danger">
                          Non-Renewal
                        </span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Total Amount</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.currency_code?.toUpperCase() }}
                        {{ formatPrice(item.total_amount, item.currency_code) }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Discount</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.currency_code?.toUpperCase() }}
                        {{
                          getDiscountPrice(
                            item.total_amount,
                            item.final_amount,
                            item.currency_code,
                          )
                        }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Final Amount</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.currency_code?.toUpperCase() }}
                        {{ formatPrice(item.final_amount, item.currency_code) }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Payment Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.payment.name }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Platform Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div class="float-left">
                          <Platform :platform="item.platform_id" />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Client ID</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ clientID(item.client_id) }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Tier Code</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item.tier_code ? item.tier_code : '-' }}
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-2">Status</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div v-if="item.is_active">
                          <span class="badge badge-pill badge-primary"
                            ><i class="fa fa-check"></i> active</span
                          >
                        </div>
                        <div v-else>
                          <span class="badge badge-pill badge-danger">
                            <i class="fa fa-close"></i> inactive</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0">
              <div class="card-header bg-primary">
                <h5>Detail Orderline</h5>
              </div>
              <div class="card-body">
                <b-table
                  id="table-transition"
                  show-empty
                  :fields="headOrderline"
                  hover
                  responsive="xl"
                  :items="item.orderlines"
                  sort-icon-left
                >
                  <template #cell(No)="row"> {{ row.index + 1 }}. </template>

                  <template #cell(id)="row">
                    {{ row.item.id }}
                  </template>

                  <template #cell(item_name)="row" class="text-justify">
                    {{ row.item.name }}
                  </template>
                  <template #cell(brand_name)="row" class="text-justify">
                    {{ row.item.brand_name }}
                  </template>

                  <template #cell(qty)="row">
                    {{ row.item.quantity }}
                  </template>

                  <template #cell(price)="row">
                    {{ row.item.currency_code }}
                    {{ formatPrice(row.item.price, row.item.currency_code) }}
                  </template>

                  <template #cell(discount)="row">
                    {{ row.item.currency_code }}
                    {{
                      getDiscountPricePerItem(
                        row.item.price,
                        row.item.final_price,
                        row.item.currency_code,
                        row.item.quantity,
                      )
                    }}
                  </template>

                  <template #cell(final_price)="row">
                    {{ row.item.currency_code }}
                    {{
                      formatPrice(row.item.final_price, row.item.currency_code)
                    }}
                  </template>
                </b-table>
              </div>
            </div>

            <br />

            <!-- ===========================================Detail Payment=========================================== -->
            <div class="card border-0">
              <div class="card-header bg-primary">
                <h5>Detail Payment</h5>
              </div>
              <div class="card-body">
                <div v-if="detailPayment">
                  <detailPaymentVue
                    :orderdetail="detailPayment"
                    :updatePaymentType="onUpdatePaymentType"
                  />
                </div>
                <div v-if="
                item.platform_id === 1                " 
                class="float-right mb-3">
                  <button class="btn btn-primary" @click="popupAppleID" v-if=" [20001,50004,20002].includes(item.order_status)">
                    Insert Apple Order ID 
                  </button>
                </div>
                <br />
                <div
                  v-if="
                    this.item.paymentgateway_id == applePay &&
                      detailPayment.paymentappleiaps
                  "
                >
                  <Itunes :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    this.item.paymentgateway_id == googlePay &&
                      detailPayment.paymentinappbillings
                  "
                >
                  <Google
                    :orderdetail="detailPayment"
                    :submitToken="onSubmitToken"
                    :submitGPA="onSubmitGPA"
                  />
                </div>
                <div
                  v-if="
                    this.item.paymentgateway_id == linkAjaPay &&
                      (detailPayment.paymentva || detailPayment.paymentdetail)
                  "
                >
                  <LinkAja :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    paymentVA.includes(parseInt(this.item.paymentgateway_id)) &&
                      (detailPayment.paymentva || detailPayment.paymentdetail)
                  "
                >
                  <VirtualAccount :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    this.item.paymentgateway_id == gopay &&
                      detailPayment.paymentgopay
                  "
                >
                  <GoPay :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    this.item.paymentgateway_id == cc && detailPayment.paymentcc
                  "
                >
                  <CreditCard :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    this.item.paymentgateway_id == free &&
                      detailPayment.orderlinediscount
                  "
                >
                  <FreePurchase :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    eTwoPayCIMB.includes(
                      parseInt(this.item.paymentgateway_id),
                    ) && detailPayment.paymentdetail
                  "
                >
                  <eTwoPayGroup :orderdetail="detailPayment" />
                </div>
                <div
                  v-if="
                    this.item.paymentgateway_id == scoopWallet && detailPayment
                  "
                >
                  <ScoopWallet :orderdetail="detailPayment" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import paymentview from '../../components/payments/payment.vue';
import Platform from '../../components/platform/platform.vue';
import Itunes from '../../components/orders/detail-itunes.vue';
import Google from '../../components/orders/detail-google.vue';
import LinkAja from '../../components/orders/detail-linkaja.vue';
import VirtualAccount from '../../components/orders/detail-virtualaccount.vue';
import GoPay from '../../components/orders/detail-gopay.vue';
import CreditCard from '../../components/orders/detail-creditcard.vue';
import FreePurchase from '../../components/orders/detail-freepurchase.vue';
import eTwoPayGroup from '../../components/orders/detail-cimbgroup.vue';
import ScoopWallet from '../../components/orders/detail-scoop-wallet.vue';
import detailPaymentVue from '../../components/payments/detail-payment.vue';
import moment from 'moment';
import constant from '../../store/constant';
const paymentVA = [
  constant.PAYMENT_GATEWAY['Virtual Account BNI'],
  constant.PAYMENT_GATEWAY['Virtual Account Permata'],
  constant.PAYMENT_GATEWAY['Virtual Account BCA'],
  constant.PAYMENT_GATEWAY['Virtual Account Mandiri'],
  constant.PAYMENT_GATEWAY['Qris Payment'],
];
const eTwoPayCIMB = [
  constant.PAYMENT_GATEWAY['CIMB Clicks'],
  constant.PAYMENT_GATEWAY['CIMB GoMobile'],
  constant.PAYMENT_GATEWAY['CIMB RekPon'],
];
export default {
  name: 'OrderDetail',
  components: {
    paymentview,
    Platform,
    Itunes,
    Google,
    LinkAja,
    VirtualAccount,
    GoPay,
    CreditCard,
    FreePurchase,
    eTwoPayGroup,
    ScoopWallet,
    detailPaymentVue,
  },
  data() {
    return {
      paymentVA,
      eTwoPayCIMB,
      googlePay: constant.PAYMENT_GATEWAY['Google In App Billing'],
      applePay: constant.PAYMENT_GATEWAY['Apple iTunes'],
      linkAjaPay: constant.PAYMENT_GATEWAY['Link Aja'],
      gopay: constant.PAYMENT_GATEWAY['Go Pay'],
      cc: constant.PAYMENT_GATEWAY['Credit Card'],
      free: constant.PAYMENT_GATEWAY['Free Purchase'],
      scoopWallet: constant.PAYMENT_GATEWAY['SCOOP Wallet'],
      headOrderline: [
        { key: 'No', label: 'No' },
        { key: 'id', label: 'Orderline ID' },
        { key: 'item_name', label: 'Item Name' },
        { key: 'brand_name', label: 'Brand Name' },
        
        { key: 'qty', label: 'Qty' },
        { key: 'price', label: 'Price' },
        { key: 'discount', label: 'Discount' },
        { key: 'final_price', label: 'Final Price' },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail Order | Content Management System Ebooks Gramedia.com';
      },
    },

    isErrorOne: function() {
      if (!this.isErrorOne) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
    item: function() {
      if (!this.item) return;

      this.getDetailPayment({
        orderID: this.item.id,
        paymentgateway: this.item.paymentgateway_id,
      });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.orders.isLoading,
      item: (state) => state.orders.item,
      detailPayment: (state) => state.payments.item,
      isErrorOne: (state) => state.orders.isErrorOne,
    }),
  },
  created: function() {
    this.fetchPage();
  },

  methods: {
    ...mapActions('orders', [
      'fetchOrdersById',
      'postOrderManuaAppleId',
      'updatePurchaseToken',
      'updateGPA',
    ]),
    ...mapActions('payments', ['getDetailPayment', 'updatePaymentType']),

    fetchPage() {
      let query = {};
      if (this.$route.query.orderid) {
        query.orderid = this.$route.query.orderid;
      }
      if (this.$route.query.ordernumber) {
        query.ordernumber = this.$route.query.ordernumber;
      }
      this.fetchOrdersById(query);
    },

    popupAppleID() {
      this.$swal.fire({
        title: 'Add Invoice Order ID',
        html:
        '<input id="order_id" type="number" disabled class="form-control mb-4" placeholder="Order id" value="' + this.item.id + '">' +
        '<input id="invoice_id"  class="form-control" placeholder="Invoice ID">',
        focusConfirm: false,
        confirmButtonText: 'Submit',
        showCancelButton: true,
        preConfirm: () => {
          const order_id = document.querySelector('#order_id').value;
          const invoice_id = document.querySelector('#invoice_id').value;

          if (!order_id || !invoice_id) {
            this.$swal.showValidationMessage('Please enter both order ID and invoice ID');
            return false;
          }

          const payload = { order_id, invoice_id };
          return payload;
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.postOrderManuaAppleId(result)
          .then((response) => {
            if (response.status == 200) {
              this.$swal('Success', 'Successfully updated', 'success');
              this.fetchPage();
            } else {
              const errorString = response?.data?.error;
              const match = errorString.match(/state: (\d+)/);
              const errorCode = match ? match[1] : null;
              if (errorCode === '90000') {
                this.$swal('Process Failed', `The data is already in PAID status.`, 'error');
              } else {
                this.$swal('Process Failed', `Process Failed. Please check the Apple Order ID you entered`, 'error');
              } 
            }
          })
          .catch((err) => {
            console.log(err)
          })
        }
      });
    },
    formatPrice(value, currencyCode) {
      if (currencyCode?.toLowerCase() == 'idr') {
        let val = (value / 1).toFixed(0).replace('.', ',');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return value;
    },
    clientID(client_id) {
      let clientName = '-';
      for (let [key, value] of Object.entries(constant.CLIENT_ID)) {
        if (parseInt(client_id) == value) {
          clientName = key;
        }
      }
      return clientName.replace('_', ' ');
    },
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
    getDiscountPrice(totalAmount, finalAmount, currency) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount = parseFloat(totalAmount) - parseFloat(finalAmount);
        return this.formatPrice(parseFloat(discount), currency);
      }
      return '-';
    },
    getDiscountPricePerItem(totalAmount, finalAmount, currency, qty) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount =
          parseFloat(totalAmount) * parseInt(qty) - parseFloat(finalAmount);
        return this.formatPrice(parseFloat(discount), currency);
      }
      return '-';
    },
    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
    async onSubmitToken({ purchase_token, order_id }) {
      this.updatePurchaseToken({
        purchase_token,
        order_id,
      })
        .then((response) => {
          if (response.status == 200) {
            this.$swal('Success', 'token successfully updated', 'success');
          } else {
            this.$swal('Process Failed', `${response?.data?.message}`, 'error');
          }
        })
        .catch(() => {
          const message =
            'Opps.. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.fetchPage();
        });
    },

    async onSubmitGPA({ google_iab, order_id }) {
      this.updateGPA({
        google_iab,
        order_id,
      })
        .then((response) => {
          if (response.status == 200) {
            this.$swal('Success', 'GPA successfully updated', 'success');
          } else {
            this.$swal('Process Failed', `${response?.data?.message}`, 'error');
          }
        })
        .catch(() => {
          const message =
            'Opps.. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.fetchPage();
        });
    },

    onUpdatePaymentType(type, id) {
      this.updatePaymentType({ type, id })
        .then((res) => {
          if (res?.status == 200) {
            this.$swal(
              'Success',
              'payment type successfully updated',
              'success',
            );
          } else {
            if (res?.status == 403) {
              this.$swal('Process Failed', `${res?.data?.message}`, 'error');
              return;
            } else {
              this.$swal(
                'Process Failed',
                `${res?.response?.data?.message}`,
                'error',
              );
            }
          }
        })
        .catch(() => {
          const message =
            'Opps.. Something is wrong,please contact your administrator';
          this.$swal('Process Failed', message, 'error');
        })
        .finally(() => {
          this.fetchPage();
        });
    },
  },
};
</script>
