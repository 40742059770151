var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.header,
      "hover": "",
      "responsive": "xl",
      "items": _vm.orderdetail.paymentappleiaps,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(purchase_date)",
      fn: function (row) {
        var _row$item, _row$item2;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item = row.item) !== null && _row$item !== void 0 && _row$item.purchase_date ? _vm.formatDate((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.purchase_date) : '-') + " ")])];
      }
    }, {
      key: "cell(product_id)",
      fn: function (row) {
        var _row$item3, _row$item4;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item3 = row.item) !== null && _row$item3 !== void 0 && _row$item3.product_id ? (_row$item4 = row.item) === null || _row$item4 === void 0 ? void 0 : _row$item4.product_id : '-') + " ")])];
      }
    }, {
      key: "cell(original_trx)",
      fn: function (row) {
        var _row$item5, _row$item6;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item5 = row.item) !== null && _row$item5 !== void 0 && _row$item5.original_transaction_id ? (_row$item6 = row.item) === null || _row$item6 === void 0 ? void 0 : _row$item6.original_transaction_id : '-') + " ")])];
      }
    }, {
      key: "cell(id_trx)",
      fn: function (row) {
        var _row$item7, _row$item8;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s((_row$item7 = row.item) !== null && _row$item7 !== void 0 && _row$item7.transaction_id ? (_row$item8 = row.item) === null || _row$item8 === void 0 ? void 0 : _row$item8.transaction_id : '-') + " ")])];
      }
    }, {
      key: "cell(purchase_token)",
      fn: function (row) {
        var _row$item9, _row$item10, _row$item11;
        return [_c('div', {
          staticStyle: {
            "width": "350px"
          }
        }, [_vm._v(" " + _vm._s((_row$item9 = row.item) !== null && _row$item9 !== void 0 && _row$item9.purchase_token ? (_row$item10 = row.item) === null || _row$item10 === void 0 ? void 0 : _row$item10.purchase_token : 'PurchaseToken Notfound') + " "), (_row$item11 = row.item) !== null && _row$item11 !== void 0 && _row$item11.purchase_token ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline"
          },
          on: {
            "click": function ($event) {
              var _row$item12;
              return _vm.copyTextToClipboard((_row$item12 = row.item) === null || _row$item12 === void 0 ? void 0 : _row$item12.purchase_token);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-copy"
        })]) : _vm._e()], 1)];
      }
    }, {
      key: "cell(receipt_version)",
      fn: function (row) {
        var _row$item13;
        return [_c('span', {
          staticClass: "badge badge-pill badge-info"
        }, [_vm._v(" " + _vm._s((_row$item13 = row.item) === null || _row$item13 === void 0 ? void 0 : _row$item13.receipt_version) + " ")])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }