<template>
  <div>
    <div class="table-responsive">
      <b-table
        id="table-transition"
        show-empty
        :fields="header"
        hover
        responsive="xl"
        :items="orderdetail.paymentappleiaps"
        sort-icon-left
      >
        <template #cell(No)="row"> {{ row.index + 1 }}. </template>
        <template #cell(purchase_date)="row">
          <div style="width: 200px">
            {{
              row.item?.purchase_date
                ? formatDate(row.item?.purchase_date)
                : '-'
            }}
          </div>
        </template>
        <template #cell(product_id)="row">
          <div style="width: 200px">
            {{ row.item?.product_id ? row.item?.product_id : '-' }}
          </div>
        </template>
        <template #cell(original_trx)="row">
          <div style="width: 200px">
            {{
              row.item?.original_transaction_id
                ? row.item?.original_transaction_id
                : '-'
            }}
          </div>
        </template>
        <template #cell(id_trx)="row">
          <div style="width: 200px">
            {{ row.item?.transaction_id ? row.item?.transaction_id : '-' }}
          </div>
        </template>
        <template #cell(purchase_token)="row">
          <div style="width: 350px">
            {{
              row.item?.purchase_token
                ? row.item?.purchase_token
                : 'PurchaseToken Notfound'
            }}
            <b-button
              v-if="row.item?.purchase_token"
              size="sm"
              variant="outline"
              @click="copyTextToClipboard(row.item?.purchase_token)"
            >
              <i class="fa fa-copy"></i>
            </b-button>
          </div>
        </template>
        <template #cell(receipt_version)="row">
          <span class="badge badge-pill badge-info">
            {{ row.item?.receipt_version }}
          </span>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'DetailItunes',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      header: [
        { key: 'No', label: 'No' },
        { key: 'purchase_date', label: 'Purchase Date' },
        { key: 'payment_id', label: 'Payment ID' },
        { key: 'product_id', label: 'Product ID' },
        { key: 'original_trx', label: 'Original Trx' },
        { key: 'id_trx', label: 'Transaction ID' },
        { key: 'receipt_version', label: 'Receipt Version' },
      ],
    };
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },
  },
};
</script>
