<template>
  <div>
    <div class="form-group row">
      <label class="col-sm-2">Discount Name</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        <router-link
          :to="{
            path:
              '/discounts-detail/' + orderdetail.orderlinediscount?.discount_id,
          }"
          class="link"
          target="_blank"
        >
          {{ orderdetail.orderlinediscount?.discount_name }}
        </router-link>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Discount Type</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{ getDiscountType(orderdetail.orderlinediscount?.discount_type) }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Discount Code</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{ orderdetail.orderlinediscount?.discount_code }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Price</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{
          formatPrice(
            orderdetail.orderlinediscount?.raw_price,
            orderdetail.orderlinediscount?.currency_code,
          )
        }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Discount</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{
          getDiscountPrice(
            orderdetail.orderlinediscount?.raw_price,
            orderdetail.orderlinediscount?.final_price,
            orderdetail.orderlinediscount?.currency_code,
          )
        }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2">Final Price</label>
      <label class="col-sm-1">:</label>
      <div class="col-sm-9">
        {{
          formatPrice(
            orderdetail.orderlinediscount?.final_price,
            orderdetail.orderlinediscount?.currency_code,
          )
        }}
      </div>
    </div>
  </div>
</template>
<script>
import constant from '../../store/constant';
import moment from 'moment';
export default {
  name: 'DetailFreePurchase',
  props: {
    orderdetail: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(tgl) {
      return moment(tgl).format('DD MMMM YYYY HH:mm:ss');
    },

    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },

    getDiscountType(type) {
      const discountType = Object.keys(constant.DISCOUNT_TYPES).find(
        (key) => constant.DISCOUNT_TYPES[key] === type,
      );
      return discountType;
    },

    getDiscountPrice(totalAmount, finalAmount, currency) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount = parseFloat(totalAmount) - parseFloat(finalAmount);
        return this.formatPrice(discount, currency);
      }
      return '-';
    },
  },
};
</script>
